import { gql } from '@apollo/client';

export const VenueStories = gql`
	query CmsGetPaginatedVenueStories(
		$pageNumber: Int!
		$perPage: Int!
		$venueName: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			venueStories(showAll: true, venueName: $venueName) {
				result {
					id
					enabled
					weight
					venue {
						id
						name
					}
				}
				paginationInfo {
					resultCount
					nextPage
					totalResult
				}
			}
		}
	}
`;

export const QueryGetIntroVideos = gql`
	query CmsGetIntroVideos {
		getIntroVideos(showAll: true) {
			id
			name
			enabled
			video {
				id
				url
			}
			introVideoDates {
				id
				date
			}
		}
	}
`;

export const QueryGetPopupAds = gql`
	query CmsGetPopupAds {
		getPopupAds(showAll: true) {
			id
			name
			enabled
			mediaLink
			media {
				id
				url
			}
			popupAdDates {
				id
				date
			}
		}
	}
`;

// [QUERY] - Get Intro Video
export const QueryGetIntroVideo = gql`
	query CmsGetIntroVideo($id: ID!) {
		getIntroVideo(id: $id) {
			id
			name
			video {
				id
				url
				type
				thumbnail {
					id
					url
				}
			}
			introVideoDates {
				id
				date
			}
		}
	}
`;
// [QUERY] - Get Intro Video
export const QueryGetPopupAd = gql`
	query CmsGetPopupAd($id: ID!) {
		getPopupAd(id: $id) {
			id
			name
			mediaLink
			media {
				id
				url
				type
			}
			popupAdDates {
				id
				date
			}
		}
	}
`;

export const QueryArticleTypes = gql`
	query CmsGetArticleTypes {
		getArticleTypes {
			id
			name
		}
	}
`;

export const AllTags = gql`
	query CmsGetAllTags {
		getAreas {
			id
			hTag
		}
		getEventTypes {
			id
			hTag
		}
		getPages {
			id
			hTag
		}
		getVenues {
			id
			hTag
		}
		getVenueTypes {
			id
			hTag
		}
	}
`;

export const QueryThingsToDo = gql`
	query CmsGetThingsToDo {
		getEventTypes(showAll: true) {
			id
			name
			hTag
			weight
			slug
			enabled
			metaDescription
			metaTitle
			metaKeywords
		}
	}
`;

export const GetThingsToDo = gql`
	query CmsGetEventType($id: ID!) {
		getEventType(id: $id) {
			id
			name
			hTag
			slug
			weight
			coverImage {
				id
				url
			}
			color
			metaDescription
			metaTitle
			metaKeywords
		}
	}
`;

export const VenueNames = gql`
	query CmsVenueNamesQuery {
		getVenues {
			id
			name
		}
	}
`;

export const QueryGetOneVenueName = gql`
	query CmsGetOneVenueName($id: ID!) {
		getVenue(id: $id) {
			id
			name
		}
	}
`;

export const QueryBestPlaces = gql`
	query CmsGetBestPlaces {
		getVenueTypes(showAll: true) {
			id
			name
			hTag
			weight
			enabled
			slug
		}
	}
`;

export const QueryMediaPagination = gql`
	query CmsMediaPagination($pageNumber: Int!, $perPage: Int!, $name: String) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			medias(name: $name) {
				result {
					id
					url
					s3Url
					name
					type
				}
				paginationInfo {
					resultCount
					nextPage
					totalResult
				}
			}
		}
	}
`;

export const QuerySliders = gql`
	query CmsGetPaginatedSliders(
		$pageNumber: Int!
		$perPage: Int!
		$title: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			sliders(showAll: true, title: $title) {
				result {
					id
					title
					description
					weight
					link
					enabled

					media {
						id
						type
						url
					}
				}
				paginationInfo {
					resultCount
					nextPage
					totalResult
				}
			}
		}
	}
`;

export const QuerySlider = gql`
	query CmsGetSlider($id: ID!) {
		getSlider(id: $id) {
			id
			title
			description
			weight
			link
			enabled
			sliderDates {
				id
				date
			}
			sliderHTags {
				id
				hTag
			}
			media {
				id
				type
				url
				thumbnail {
					id
					url
				}
			}
		}
	}
`;

export const VenueStory = gql`
	query CmsGetVenueStory($id: ID!) {
		getVenueStory(id: $id) {
			id
			enabled
			venueStoryHTags {
				id
				hTag
			}
			venue {
				id
				name
			}
			storyItems(showAll: true) {
				id
				enabled
				weight
				link
				media {
					id
					url
					type
				}
				storyItemHTags {
					id
					hTag
				}
				storyItemDates {
					id
					date
				}
			}
		}
	}
`;

export const QueryDiscoverTheCity = gql`
	query CmsGetDiscoverTheCity {
		getAreas(showAll: true) {
			id
			name
			hTag
			weight
			enabled
			slug
			featured
		}
	}
`;

export const QueryGetVenues = gql`
	query CmsGetVenues($pageNumber: Int!, $perPage: Int!, $name: String) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			venues(showAll: true, name: $name) {
				result {
					id
					name
					enabled
					weight
					verified
					partner
					status
				}
				paginationInfo {
					resultCount
					nextPage
					totalResult
				}
			}
		}
	}
`;

export const QueryGetVenue = gql`
	query CmsGetVenue($id: ID!) {
		getVenue(id: $id) {
			id
			name
			properName
			weight
			details {
				info
				famousFor
				dateTiming
				dressCode
				map
				virtualTour
			}
			slug
			meta
			partner
			verified
			hTag
			longitude
			latitude
			ctaNumber
			ctaLink
			status
			logo {
				id
				url
			}
			area {
				id
			}
			venueTypes {
				id
			}
			venuePhotos {
				id
				url
			}
			venueMenus {
				id
				url
			}
			coverImage {
				id
				url
			}
		}
	}
`;

export const QueryGetVenueType = gql`
	query CmsGetVenueType($id: ID!) {
		getVenueType(id: $id) {
			id
			name
			hTag
			description
			slug
			meta
			weight
			coverImage {
				id
				url
			}
		}
	}
`;

export const QueryGetArea = gql`
	query CmsGetArea($id: ID!) {
		getArea(id: $id) {
			id
			name
			hTag
			slug
			weight
			meta
			coverImage {
				id
				url
			}
		}
	}
`;

// [QUERY] - Get Offers

export const QueryGetOffers = gql`
	query CmsGetPaginatedOffers(
		$pageNumber: Int!
		$perPage: Int!
		$name: String
		$venueId: ID
		$offerTypeId: ID
		$venueName: String
		$approved: Boolean
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			offers(
				showAll: true
				order: CREATED_DESC
				name: $name
				venueId: $venueId
				offerTypeId: $offerTypeId
				venueName: $venueName
				approved: $approved
			) {
				result {
					id
					title
					weight
					enabled
					featured
					description
					approved
					venue {
						id
						name
					}
					offerType {
						id
						name
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

export const QueryGetEventPhotographersView = gql`
	query CmsGetPaginatedEventPhotographers(
		$pageNumber: Int!
		$perPage: Int!
		$withPhotos: Boolean
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			eventPhotographers(withPhotos: $withPhotos) {
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
				result {
					id
					adminComment
					photographerComment
					eventDate {
						id
						eDate
						event {
							id
							name
						}
						eventParent {
							id
							venue {
								id
								name
							}
						}
					}
				}
			}
		}
	}
`;

export const QueryGetEventPhotographer = gql`
	query CmsGetEventPhotographer($id: ID!) {
		getEventPhotographer(id: $id) {
			id
			adminComment
			photographerComment
			photographer {
				id
			}
			eventDate {
				id
				eDate
				event {
					id
					name
				}
				eventParent {
					id
					venue {
						id
						name
					}
				}
			}
			eventPhotos {
				id
				media {
					id
					url
				}
			}
		}
	}
`;

// [QUERY] - Get Offer
export const QueryGetOffer = gql`
	query CmsGetOffer($id: ID!) {
		getOffer(id: $id) {
			id
			title
			weight
			enabled
			featured
			description
			approved
			offerSource {
				fName
				lName
				phone
				email
			}
			venue {
				id
				name
			}
			coverImage {
				id
				url
			}
			offerDates {
				id
				date
			}
			offerHTags {
				id
				hTag
			}
			offerType {
				id
				name
			}
		}
	}
`;

// [QUERY] - Get Events
export const QueryGetEvents = gql`
	query CmsGetPaginationEventDates(
		$pageNumber: Int!
		$perPage: Int!
		$photographer: Boolean
		$name: String
		$venueId: ID
		$photographerId: ID
		$venueName: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			eventDates(
				showAll: true
				photographer: $photographer
				name: $name
				venueId: $venueId
				photographerId: $photographerId
				venueName: $venueName
			) {
				result {
					id
					eDate
					featured
					enabled
					photographer
					hasAlbum
					weight
					eventPhotosCount {
						approved
						pending
						totalPhoto
					}
					photographers {
						id
						adminComment
						photographerComment
						eventDate {
							id
						}
						photographer {
							id
							fName
							lName
						}
					}
					event {
						id
						name
					}
					albumCover {
						id
						url
					}
					eventParent {
						id
						parentName
						venue {
							id
							name
						}
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

export const QueryGetPhotographers = gql`
	query CmsGetPhotographers {
		photographers: getUsers(type: PHOTOGRAPHER) {
			id
			fName
			lName
		}
	}
`;

// [QUERY] - Get Event
export const QueryGetEvent = gql`
	query CmsGetEvent($id: ID!) {
		getEventDate(id: $id) {
			id
			eDate
			featured
			photographer
			event {
				id
				name
				description
				offers
				ctaLink
				ctaNumber
				video {
					id
					url
				}
				poster {
					id
					url
				}
			}
			eventParent {
				id
				parentName
				slug
				occurrenceTime
				hTag
				approved
				source
				meta
				eventSource {
					fName
					lName
					phone
					email
				}
				eventDates {
					id
					eDate
				}
				eventTypes {
					id
					name
				}
				venue {
					id
					name
				}
				genres {
					id
					name
				}
			}
		}
	}
`;

// [QUERY] - Get Genres
export const QueryGetGenres = gql`
	query CmsGetGenres {
		getGenres(showAll: true) {
			id
			name
			enabled
		}
	}
`;

export const QueryFeaturedList = gql`
	query CmsFeaturedList($hTag: String!) {
		getFeaturedListByHTag(hTag: $hTag) {
			id
			weight
			article {
				id
				title
			}
		}
	}
`;

export const QueryMe = gql`
	query CmsMe {
		me {
			id
			fName
			lName
			email
			dob
			userType
		}
	}
`;

export const QueryGetEventPhotos = gql`
	query CmsGetEventPhotos($id: ID!) {
		getEventDate(id: $id) {
			id
			eDate
			albumCover {
				id
				url
				type
			}
			event {
				id
				name
			}
			eventParent {
				id
				venue {
					id
					name
				}
			}
			eventPhotos(showAll: true) {
				id
				approved
				weight
				media {
					id
					url
				}
				eventPhotographer {
					id
				}
			}
			photographers {
				id
				photographer {
					id
					fName
					lName
				}
			}
		}
	}
`;

// [QUERY] - Get Articles
export const QueryPaginatedArticles = gql`
	query CmsGetPaginatedArticles(
		$pageNumber: Int!
		$perPage: Int!
		$title: String
		$featured: Boolean
		$articleTypeSlug: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			articles(
				showAll: true
				enableScrap: false
				order: WEIGHT_ASC
				title: $title
				featured: $featured
				articleTypeSlug: $articleTypeSlug
			) {
				result {
					id
					title
					date
					weight
					featured
					enabled
					slug
					articleType {
						id
						name
						slug
					}
					counters {
						id
						refId
						aspect
						value
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

// [QUERY] - Get Articles
export const QueryGetArticlesWithComments = gql`
	query CmsGetPaginatedArticlesWithComments($pageNumber: Int!, $perPage: Int!) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			articles(showAll: true, enableScrap: false, onlyWithComments: true) {
				result {
					id
					title
					date
					weight
					featured
					enabled
					slug
					comments {
						id
						comment
						date
						user {
							id
							fName
							lName
							email
						}
						replies {
							id
							comment
							date
							user {
								id
								fName
								lName
								email
							}
						}
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

// [QUERY] - Get Article
export const QueryGetArticle = gql`
	query CmsGetArticle($id: ID!) {
		getArticle(id: $id) {
			id
			title
			slug
			weight
			featured
			date
			content
			meta
			scrapDate
			enabled
			publishTime
			articleHTags {
				id
				hTag
			}
			articleType {
				id
				name
			}
			articleJumpTos {
				id
				articleId
				jumpToLink
				jumpToText
			}
			media {
				id
				type
				url
			}
		}
	}
`;

// [QUERY] - Get Event Photographers
export const QueryGetEventPhotographers = gql`
	query CmsGetPaginatedPhotographerEvents(
		$pageNumber: Int!
		$perPage: Int!
		$eventName: String
		$photographerId: ID
		$venueName: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			eventDates(
				showAll: true
				photographer: true
				name: $eventName
				photographerId: $photographerId
				venueName: $venueName
			) {
				result {
					id
					eDate
					featured
					enabled
					photographers {
						id
						adminComment
						photographerComment
						photographer {
							id
							fName
							lName
						}
					}
					event {
						id
						name
					}
					albumCover {
						id
						url
					}
					eventParent {
						id
						parentName
						venue {
							id
							name
						}
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

// [QUERY] - Get Venue Videos
export const QueryGetVenueVideos = gql`
	query CmsGetVenueVideos {
		getVenueVideos(showAll: true) {
			id
			name
			venue {
				name
			}
			weight
			enabled
			video {
				url
			}
		}
	}
`;

// [QUERY] - Get Venue Video
export const QueryGetVenueVideo = gql`
	query CmsGetVenueVideo($id: ID!) {
		getVenueVideo(id: $id) {
			id
			name

			venue {
				id
				name
			}
			weight
			videoType {
				id
				name
			}
			video {
				id
				url
				thumbnail {
					id
					url
				}
			}
			venueVideoHTag {
				id
				hTag
			}
		}
	}
`;

// [QUERY] - Get Event Parent
export const QueryGetEventParents = gql`
	query CmsGetEventParents {
		getEventParents {
			id
			events {
				id
				name
			}
			venue {
				id
				name
			}
			eventDates {
				id
				eDate
			}
			eventSource {
				id
				fName
				lName
			}
		}
	}
`;

export const QueryPendingGetEventParents = gql`
	query CmsGetPendingEventParents {
		getEventParents(approved: false) {
			id
			parentName
			events {
				id
				name
			}
			venue {
				id
				name
			}
			eventDates {
				id
				eDate
			}
			source
			eventSource {
				id
				fName
				lName
				phone
				email
			}
		}
	}
`;

// [QUERY] - Get Users

export const QueryGetUsers = gql`
	query CmsGetPaginatedUsers(
		$type: Role
		$pageNumber: Int!
		$perPage: Int!
		$showAll: Boolean
		$email: String
	) {
		pagination(pageNumber: $pageNumber, perPage: $perPage) {
			users(type: $type, showAll: $showAll, email: $email) {
				result {
					id
					gender
					fName
					lName
					enabled
					dob
					email
					about
					userType
					phone
					photographerInfo {
						id
						approved
						cameraType
						drive
						freeTime
					}
				}
				paginationInfo {
					resultCount
					totalResult
					nextPage
				}
			}
		}
	}
`;

// [QUERY] - Get Video Types

export const QueryGetVideoTypes = gql`
	query CmsGetVideoTypes($showAll: Boolean!) {
		getVideoTypes(showAll: $showAll) {
			id
			name
			description
			weight
			enabled
		}
	}
`;

// [QUERY] - Get Video Type

export const QueryGetVideoType = gql`
	query CmsGetVideoType($id: ID!) {
		getVideoType(id: $id) {
			id
			name
			description
			weight
			enabled
		}
	}
`;

// [QUERY] - Get Article Types

export const QueryGetArticleTypes = gql`
	query CmsGetAllArticleTypes {
		getArticleTypes(showAll: true) {
			id
			name
			description
			weight
			enabled
			slug
		}
	}
`;

// [QUERY] - Get Article Type

export const QueryGetArticleType = gql`
	query CmsGetArticleType($id: ID!) {
		getArticleType(id: $id) {
			id
			name
			description
			weight
			enabled
			slug
		}
	}
`;

// [QUERY] - Get Offer Types

export const QueryGetOfferTypes = gql`
	query CmsGetOfferTypes {
		getOfferTypes {
			id
			name
			enabled
		}
	}
`;

// [QUERY] - Get Current Region

export const QueryGetCurrentRegion = gql`
	query CmsGetCurrentRegion {
		getCurrentRegion {
			id
			name
			code
			countryCode
			domainName
			cmsDomainName
			apiDomainName
			timezone
			details {
				brandName
				tagLine
				address
				email
				phone
				terms
				privacy
				socialInstagram
				socialFb
				socialTwitter
			}
		}
	}
`;

// [QUERY] - Get Ad Units

export const QueryGetAdUnits = gql`
	query CmsGetAdUnits {
		getAdUnits {
			id
			code
			type
		}
	}
`;

// [QUERY] - Get All Regions

export const QueryGetRegions = gql`
	query CmsGetRegions {
		getRegions {
			id
			domainName
			cmsDomainName
			name
		}
	}
`;

// [QUERY] - Get Report

export const QueryGetReports = gql`
	query GetVenueReportToDownload(
		$refId: String!
		$startDate: String!
		$endDate: String!
	) {
		getVenueReportToDownload(
			refId: $refId
			startDate: $startDate
			endDate: $endDate
		) {
			buffer
			fileName
		}
	}
`;

export const QueryGetReportsToEmail = gql`
	query GetVenueReportToEmail(
		$refId: String
		$startDate: String
		$endDate: String
		$email: String
	) {
		getVenueReportToEmail(
			refId: $refId
			startDate: $startDate
			endDate: $endDate
			email: $email
		)
	}
`;
