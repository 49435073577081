import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Skeleton } from 'antd';
import Text from 'antd/lib/typography/Text';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomNotification } from 'src/components/custom-notification';
import PopupAdCard from 'src/components/ui/popup-ad/popup-ad-card';
import { ROUTES } from 'src/config/route';
import { PopupAdForm } from 'src/lib/form-interfaces';
import { CmsGetPopupAdQuery } from 'src/lib/generated/_generated';
import {
	CmsDeletePopupAd,
	CmsDeletePopupAdVariables,
} from 'src/lib/gql/generated/CmsDeletePopupAd';
import { CmsGetPopupAdVariables } from 'src/lib/gql/generated/CmsGetPopupAd';
import {
	CmsUpdatePopupAd,
	CmsUpdatePopupAdVariables,
} from 'src/lib/gql/generated/CmsUpdatePopupAd';
import {
	MutationDeletePopupAd,
	MutationUpdatePopupAd,
} from 'src/lib/gql/mutations';
import { QueryGetPopupAd } from 'src/lib/gql/queries';

const EditPopupAd = () => {
	const params = useParams<{ id: string }>();
	const navigate = useNavigate();

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPopupAdQuery, CmsGetPopupAdVariables>(QueryGetPopupAd, {
		variables: {
			id: params.id ?? '',
		},
	});

	const [UpdatePopupAd] = useMutation<
		CmsUpdatePopupAd,
		CmsUpdatePopupAdVariables
	>(MutationUpdatePopupAd, {
		onError(error) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const updateQry = useCallback(async (data: PopupAdForm) => {
		const rsp = await UpdatePopupAd({
			variables: {
				id: params.id ?? '',
				name: data.name,
				btnText: data.btnText,
				mediaLink: data.mediaLink,
				mediaId: data.mediaId,
				dates: data.dates.map((date) => format(date, 'yyyy-MM-dd')),
			},
		});

		if (rsp.data) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'updated',
			});

			refetchQuery();
		}
	}, []);

	const [DeletePopupAd] = useMutation<
		CmsDeletePopupAd,
		CmsDeletePopupAdVariables
	>(MutationDeletePopupAd, {
		onError(error) {
			CustomNotification({
				pageName: 'Popup Ad',
				pagePrefix: 'PopupAd',
				notificationType: 'custom-error',
				customDescription: error.message,
			});
		},
	});

	const deleteQry = useCallback(
		async (id) => {
			if (!confirm('Are you sure you want to delete?')) return;
			const rsp = await DeletePopupAd({
				variables: {
					id: params.id ?? '',
				},
			});

			if (rsp.data) {
				CustomNotification({
					pageName: 'Popup Ad',
					pagePrefix: 'PopupAd',
					notificationType: 'deleted',
				});

				navigate(`${ROUTES.POPUP_AD.path}`);
			}
		},
		[params.id],
	);

	return (
		<Row>
			<Col span={24}>
				<Text className="sub-header">Edit Popup Ad</Text>
			</Col>
			<Row style={{ width: '100%' }} gutter={16}>
				{loading ? (
					<Skeleton active />
				) : (
					<PopupAdCard
						popupData={qryData}
						canDelete={true}
						onSave={(data) => {
							// console.log(data.dates);
							updateQry({
								dates: data.dates,
								name: data.name,
								mediaId: data.mediaId,
								btnText: data.btnText,
								mediaLink: data.mediaLink,
							});
						}}
						onDelete={deleteQry}
					/>
				)}
			</Row>
		</Row>
	);
};

export default EditPopupAd;
