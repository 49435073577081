import { Card, Col, Image, Input, Modal, notification } from 'antd';
import Text from 'antd/lib/typography/Text';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { MediaType } from 'src/lib/gql/generated/globalTypes';
import { uploadFile } from '../../lib/axios';
import { Media, MediaFoldersNames } from '../../lib/interface';
import { CustomNotification } from '../custom-notification';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

interface Props {
	accept: 'IMAGE' | 'VIDEO' | 'BOTH';
	folder: MediaFoldersNames;
	watermark?: boolean;
	media?: any;
	height?: string;
	placeholderText?: string;
	onMediaUploaded: (media: Media) => void;
}

const MediaUpload = React.memo(
	({
		media,
		onMediaUploaded,
		accept,
		height = '400px',
		placeholderText = 'Drag and drop a photo or video here.',
		folder,
		watermark = false,
	}: Props) => {
		const [fileName, setFileName] = useState<string | undefined>(undefined);
		const nMedia = useRef<Media | null>(media || null);
		const [mediaId, setMediaId] = useState<string | null>(media?.id || null);
		const [uploading, setUploading] = useState<boolean>(false);
		const selectedFile = useRef<File | null>(null);
		const [showMediaModal, setShowMediaModal] = useState<boolean>(false);
		const fileAccept = {
			IMAGE: 'image/*',
			VIDEO: 'video/*',
			BOTH: 'image/*, video/*',
		};

		useEffect(() => {
			nMedia.current = media || null;
			setMediaId(media?.id || null);
			return () => {
				//
			};
		}, [media?.id]);

		const uploadMedia = async (file: File) => {
			if (!uploading) setUploading(true);
			notification['info']({
				message: 'Uploading',
				description: 'File is uploading, wait!',
				duration: 2,
			});
			uploadFile({
				file: file,
				folder: folder,
				watermark: watermark,
				name: fileName,
			})
				.then((re) => {
					if (re.data.data) {
						nMedia.current = {
							id: re.data.data.id,
							type: re.data.data.type,
							url: re.data.data.url,
						};
						setMediaId(re.data.data.id);
						onMediaUploaded(nMedia.current);
					}
					CustomNotification({
						pageName: 'Upload',
						customDescription: 'File has been uploaded successfully.',
						notificationType: 'custom-success',
					});
				})
				.catch((err: unknown) => {
					let errorMessage = 'Media upload failed from server';
					if (axios.isAxiosError(err)) {
						const axiosError = err as AxiosError<any>;
						errorMessage =
							axiosError.response?.data?.response?.message || err.message;
						if (axiosError.response?.status === 403) {
							const app = getApp();
							const auth = getAuth(app);
							auth.signOut();
							localStorage.removeItem('token');
							localStorage.removeItem('authUser');
						}
					}
					CustomNotification({
						pageName: 'Upload',
						customDescription: errorMessage,
						notificationType: 'custom-error',
					});
				});
			setUploading(false);
		};

		return (
			<Card
				style={{
					height: '100%',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
				styles={{ body: { height: height } }}
			>
				<Modal
					zIndex={1000}
					title="Confirm Upload"
					open={showMediaModal}
					okText="Upload"
					centered
					onOk={async () => {
						if (selectedFile.current !== null) {
							setShowMediaModal(false);
							await uploadMedia(selectedFile.current);
							selectedFile.current = null;
						}
					}}
					onCancel={() => {
						selectedFile.current = null;
						setShowMediaModal(false);
					}}
				>
					{selectedFile.current?.type.search('video') !== -1 ? (
						<video
							width="100%"
							height="100%"
							autoPlay={true}
							controls={true}
							style={{
								objectFit: 'contain',
								objectPosition: 'center',
							}}
							src={
								(selectedFile.current &&
									URL.createObjectURL(selectedFile.current)) ||
								''
							}
						/>
					) : (
						<Image
							src={
								(selectedFile.current &&
									URL.createObjectURL(selectedFile.current)) ||
								''
							}
							width="100%"
							height="100%"
							style={{
								objectFit: 'contain',
								objectPosition: 'center',
							}}
						/>
					)}

					<Input
						value={fileName}
						onChange={(e) => setFileName(e.target.value)}
						placeholder="Name of Image (for SEO)"
					/>
				</Modal>
				<Input
					type="file"
					id="file"
					style={{
						opacity: 0,
						width: '100%',
						height: '100%',
						cursor: 'pointer',
						position: 'absolute',
						top: 0,
						left: 0,
					}}
					onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
						if (val.target.files && val.target.files[0]) {
							selectedFile.current = val.target.files[0];
							setShowMediaModal(true);
						}
					}}
					value={''}
					accept={fileAccept[accept]}
				/>
				<Col
					span={24}
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						userSelect: 'none',
						textAlign: 'center',
						pointerEvents: 'none',
						width: '100%',
						height: '100%',
					}}
				>
					{mediaId !== null ? (
						<>
							<div
								style={{
									position: 'absolute',
									left: '0',
									userSelect: 'none',
									textAlign: 'center',
									display: 'block',
									zIndex: 2,
									width: '100%',
									height: '100%',
									backgroundColor: 'rgba(0,0,0,0.30)',
								}}
							>
								<Text
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										userSelect: 'none',
										textAlign: 'center',
										fontSize: '2rem',
										width: '100%',
										color: 'white',
										textTransform: 'uppercase',
										letterSpacing: '0.05em',
									}}
									strong={true}
								>
									Click to Replace Media
								</Text>
							</div>
							{nMedia.current?.type === MediaType.VIDEO ? (
								<video
									width="100%"
									height="100%"
									autoPlay={true}
									loop={true}
									style={{
										objectFit: 'contain',
										objectPosition: 'center',
									}}
									src={nMedia.current?.url}
								/>
							) : (
								<Image
									src={nMedia.current?.url}
									width="100%"
									height="100%"
									style={{
										objectFit: 'contain',
										objectPosition: 'center',
									}}
								/>
							)}
						</>
					) : (
						<>
							<Text
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									opacity: '0.25',
									display: 'block',
									width: '100%',
									cursor: 'pointer',
								}}
								strong={true}
							>
								{placeholderText}
							</Text>
						</>
					)}
				</Col>
			</Card>
		);
	},
);

export default MediaUpload;
